<header>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <ul class="nav-menu main-nav-menu" *ngIf="!USER">
          <li class="nav-item logo">
            <a>
              <img src="../../../../assets/images/logo_new.svg" alt="logo">
            </a>
          </li>
        </ul>
        <ul class="nav-menu main-nav-menu" *ngIf="USER">
          <li class="nav-item logo">
            <a [routerLink]="['/']">
              <img src="../../../../assets/images/logo_new.svg" alt="logo">
              <!--<span>Digital Signature</span>-->
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'">
            <a [routerLink]="['/users']">Пользователи</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'">
            <a [routerLink]="['/topics']">Темы</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'">
            <a [routerLink]="['/main-questions']">Основные вопросы</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'">
            <a [routerLink]="['/moderation/questions']">Персональные вопросы</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'">
            <a [routerLink]="['/questions-of-the-day']">Вопросы дня</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'">
            <a [routerLink]="['/moderation/comments']">Комментарии</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'">
            <a [routerLink]="['/analytics/users']">Аналитика</a>
          </li>
        </ul>
        <ul class="nav-menu" *ngIf="USER">
          <!--<li class="nav-item profile" [routerLinkActive]="'active'">
            <a class="user-profile" [routerLink]="['/user-profile']">
              <i class="fas fa-user"></i>
            </a>
          </li>-->
          <li class="nav-item logout">
            <a class="logout_btn" (click)="logout()">Выйти</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
