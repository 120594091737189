import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //const requiredRole = route.data['role'];
    //const requiredMode = route.data['mode'];
    const user = JSON.parse(localStorage.getItem('currentUser'));
    //const mode = localStorage.getItem('mode');
    /*console.log(requiredRole + ' / ' + requiredMode);
    console.log(user);
    console.log(mode);*/
    if (user) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }

    /*if (localStorage.getItem('currentUser') && (requiredRole === user.role || requiredRole === 'anyone')) {
      return true;
    } else if (localStorage.getItem('currentUser') && (user.role === 'administrator' && requiredRole === 'administrator')) {
      this.router.navigate(['/departments']);
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }*/
  }
}
