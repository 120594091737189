<div class="container login">
  <div class="row">
    <div class="main-content col-12">

      <form name="loginForm" id="loginForm" class="loginForm col-12 col-sm-8 col-md-6 col-lg-4 col-xl-4" (ngSubmit)="loginForm.valid && login(loginForm)" [formGroup]="loginForm" #f="ngForm" [class.ng-submitted-error]="f.submitted && f.form.invalid" novalidate>

        <!-- <div class="form-group required_section email">
          <div class="input-wrapper">
            <label for="email">
              {{ 'Email'  }}
              <span class="required_asterisk">*</span>
            </label>
            <input type="email" placeholder="name@email.com" class="form-control" name="email" id="email" formControlName="email"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!loginForm.controls.email.errors">
            <p *ngIf="!!loginForm.controls.email.errors.required">
              {{'Field_is_required' }}
            </p>
            <p *ngIf="!!loginForm.controls.email.errors.pattern">
              {{'Email_isnt_valid' }}
            </p>
            <p *ngIf="!!loginForm.controls.email.errors.maxlength">
              {{'Field_is_too_long' }}
            </p>
          </div>
        </div> -->

        <div class="form-group required_section username">
          <div class="input-wrapper">
            <label for="email">
              {{ 'Email'  }}
              <span class="required_asterisk">*</span>
            </label>
            <input type="text" class="form-control" name="email" id="email" formControlName="email"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!loginForm.controls.email.errors">
            <p *ngIf="!!loginForm.controls.email.errors.required">
              Поле, обязательное для заполнения
            </p>
            <p *ngIf="!!loginForm.controls.email.errors.pattern">
              Электронная почта недействительна
            </p>
            <p *ngIf="!!loginForm.controls.email.errors.maxlength">
              Электронная почта слишком длинная
            </p>
          </div>
        </div>

        <div class="form-group required_section password">
          <div class="input-wrapper">
            <label for="password">
              {{ 'Password'  }}
              <span class="required_asterisk">*</span>
            </label>
            <input type="password" class="form-control" name="password" id="password" formControlName="password"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && loginForm.controls['password'].errors">
            <p *ngIf="!!loginForm.controls['password'].errors.required">
              Поле, обязательное для заполнения
            </p>
            <p *ngIf="!!loginForm.controls['password'].errors.minlength">
              Длина пароля должна быть не менее 5 символов
            </p>
          </div>
        </div>

        <div class="form-controls">
          <div class="controls-wrapper">
            <button class="main-action-btn form-submit-btn" type="submit">
              {{'Login' }}
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
