import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { EnvServiceFactory} from './_SERVICES';
import {AuthGuard} from './_GUARDS';
import {TopicsComponent} from './topics/topics.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main-questions',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'topics',
    component: TopicsComponent
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'main-questions',
    loadChildren: () => import('./main-questions/main-questions.module').then(m => m.MainQuestionsModule)
  },
  {
    path: 'questions-by-interests',
    canActivate: [AuthGuard],
    loadChildren: () => import('./questions-by-interests/questions-by-interests.module').then(m => m.QuestionsByInterestsModule)
  },

  {
    path: 'questions-of-the-day',
    canActivate: [AuthGuard],
    loadChildren: () => import('./question-of-the-day/question-of-the-day.module').then(m => m.QuestionOfTheDayModule)
  },
  {
    path: 'moderation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./moderation/moderation.module').then(m => m.ModerationModule)
  },
  {
    path: 'analytics',
    canActivate: [AuthGuard],
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule)
  },
  { path: '**', redirectTo: 'main-questions' }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled'
    })],
  declarations: []
})
export class AppRoutingModule {}
