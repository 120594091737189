import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import { EnvService } from './env.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  USER: any;

  constructor(
    private env: EnvService,
    private http: HttpClient,
    private alertService: AlertService
  ) {}

  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }

  public getUsersList(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/users`;
    this.getUserData();
    console.log(data.itemsPerPage);
    const params = new HttpParams()
      .append('query', data.search)
      .append('order_by', data.sortField)
      .append('order_direction', data.reverseOrder ? 'asc' : 'desc')
      .append('perPage', data.itemsPerPage)
      .append('page', data.currentPage);
    return this.http.get<Object>(url, {params});
  }

  public deleteUser(id): Observable<any> {
    const url = `${this.env.apiBase}/admin/users`;
    let params = new HttpParams()
      .append('user_id', id);
    return this.http.delete<Object>(url, {params});
  }


}
