import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import { EnvService } from './env.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  USER: any;

  constructor(
    private env: EnvService,
    private http: HttpClient,
    private _AlertService: AlertService
  ) {}

  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }

  importMainQuestions(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions/import`;
    const formData: FormData = new FormData();
    formData.append('file', data.file[0]);
    formData.append('is_delete', data.import_option);
    console.log(formData);
    return this.http.post(url, formData);
  }

  public getMainQuestionsList(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions`;
    this.getUserData();
    console.log(data.itemsPerPage);
    const params = new HttpParams()
      .append('is_day', '0')
      .append('query', data.search)
      .append('order_by', data.sortField)
      .append('order_direction', data.reverseOrder ? 'asc' : 'desc')
      .append('perPage', data.itemsPerPage)
      .append('page', data.currentPage);
    return this.http.get<Object>(url, {params});
  }

  public getSingleQuestion(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions`;
    this.getUserData();
    console.log(data.itemsPerPage);
    const params = new HttpParams()
      .append('is_day', data.is_day)
      .append('id', data.id);
    return this.http.get<Object>(url, {params});
  }

  public getModerationQuestionsList(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/personal_questions`;
    this.getUserData();
    console.log(data.itemsPerPage);
    const params = new HttpParams()
      .append('is_accept', data.is_accept)
      .append('date_from', data.dateStart)
      .append('date_to', data.dateEnd)
      .append('order_by', data.sortField)
      .append('order_direction', data.reverseOrder ? 'desc' : 'asc')
      .append('query', data.search)
      .append('perPage', data.itemsPerPage)
      .append('page', data.currentPage);
    return this.http.get<Object>(url, {params});
  }

  public deleteQuestion(id): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions/${id}`;
    let params = new HttpParams()
      .append('id', id);
    return this.http.delete<Object>(url, {params});
  }


  public deletePersonalQuestion(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/personal_questions`;
    const params = new HttpParams()
      .append('question_id', data.question_id)
      .append('reason', data.reason.toString());
    return this.http.delete<Object>(url, {params});
  }

  public getModerationCommentsList(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/comments`;
    this.getUserData();
    console.log(data.itemsPerPage);
    const params = new HttpParams()
      .append('date_from', data.dateStart)
      .append('date_to', data.dateEnd)
      .append('is_accept', data.is_accept)
      .append('query', data.search)
      .append('order_by', data.sortField)
      .append('order_direction', data.reverseOrder ? 'asc' : 'desc')
      .append('perPage', data.itemsPerPage)
      .append('page', data.currentPage);
    return this.http.get<Object>(url, {params});
  }

  public deleteComment(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/comments`;
    const params = new HttpParams()
      .append('is_personal', data.is_personal)
      .append('comment_id', data.comment_id)
      .append('reason', data.reason.toString());
    return this.http.delete<Object>(url, {params});
  }

  public acceptRestoreQuestion(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/personal_questions/accept_or_restore`;
    /*const params = new HttpParams()
      .append('question_id', data.question_id);*/
    return this.http.patch<Object>(url, {question_id: data.question_id});
  }

  public acceptRestoreComment(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/comments/accept_or_restore`;
    /*const params = new HttpParams()
      .append('question_id', data.question_id);*/
    return this.http.patch<Object>(url, {
      comment_id: data.comment_id,
      is_personal: data.is_personal
    });
  }

  public getTopicsList(): Observable<any> {
    const url = `${this.env.apiBase}/admin/topics`;
    this.getUserData();
    return this.http.get<Object>(url, {});
  }

  editTopic(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/topics/${data.id}`;
    return this.http.put(url, data);
  }

  public deleteTopic(id): Observable<any> {
    const url = `${this.env.apiBase}/admin/topics/${id}`;
    let params = new HttpParams()
      .append('id', id);
    return this.http.delete<Object>(url, {params});
  }

  public getGoalsList(): Observable<any> {
    const url = `${this.env.apiBase}/admin/goals`;
    this.getUserData();
    return this.http.get<Object>(url, {});
  }

  createNewQuestion(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions`;
    return this.http.post(url, data);
  }

  editQuestion(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions/${data.id}`;
    return this.http.put(url, data);
  }

  convertToMainQuestion(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/personal_questions/to/question`;
    return this.http.put(url, data);
  }

  createNewTopic(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/topics`;
    return this.http.post(url, data);
  }

  sendNotification(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/change/answer/notification`;
    return this.http.post(url, data);
  }

  public getDayQuestionsList(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions`;
    this.getUserData();
    console.log(data.itemsPerPage);
    const params = new HttpParams()
      .append('is_day', '1')
      .append('query', data.search)
      .append('order_by', data.sortField)
      .append('order_direction', data.reverseOrder ? 'asc' : 'desc')
      .append('perPage', data.itemsPerPage)
      .append('page', data.currentPage);
    return this.http.get<Object>(url, {params});
  }

  public getSingleDayQuestion(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions`;
    this.getUserData();
    console.log(data.itemsPerPage);
    const params = new HttpParams()
      .append('is_day', '1')
      .append('id', data.id);
    return this.http.get<Object>(url, {params});
  }

  changeIsDayType(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/questions/${data.id}/is-day`;
    return this.http.put(url, data);
  }
}
