import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {QuestionsService} from '../_SERVICES';
import {Subject} from 'rxjs';
import {AlertService} from '../_SERVICES';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.less']
})
export class TopicsComponent implements OnInit {
  createTopicForm: FormGroup;

  modalConfirmRef: BsModalRef;
  modalTopicRef: BsModalRef;
  loadingIndicator = false;
  colorsDropdown = false;

  topicIdToChange = null;
  isEdit = false;

  colorsData = {current: '#ffffff', list: [], custom_list: []};

  searchModelChanged: Subject<string> = new Subject<string>();

  topicsList: any[];
  pConfig = {
    itemsPerPage: 10,
    currentPage: 1,
    sortField: 'name',
    reverseOrder: false,
    search: '',
    search_strings: 'name,topic_order',
    totalItems: 0
  };

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private questionsService: QuestionsService,
    private AlertService: AlertService
  ) { }

  ngOnInit(): void {
    this.modalService.onHide
      .subscribe(res => {
        this.colorsData = {current: '#ffffff', list: this.topicsList, custom_list: []};
        this.colorsDropdown = false;
        this.isEdit = false;
        this.topicIdToChange = null;
      });

    this.getTopics();
  }

  sortBy(sortField): void {
    this.pConfig.reverseOrder = (this.pConfig.sortField === sortField) ? !this.pConfig.reverseOrder : false;
    this.pConfig.sortField = sortField;
    //this.getTopics();
  }
  pageChange(event: any): void {
    this.pConfig.currentPage = event;
    //this.getTopics();
  }
  perPageChange(data): void {
    this.pConfig.currentPage = 1;
    this.pConfig.itemsPerPage = data;
    //this.getTopics();
  }
  search(e): void {
    this.searchModelChanged.next(e.target.value);
  }

  getTopics(): void {
    this.questionsService.getTopicsList().subscribe(res => {
      /*res.forEach(obj => {
        console.log(obj);
        this.colorsData.list.push(obj);
      });*/
      this.topicsList = res;
    });
  }

  public createTopicFormInit(item?): void {
    this.createTopicForm = this.fb.group({
      name: [item ? item.name : '', this.isEdit ? [Validators.required] : [Validators.required, this.checkTopicDuplicate(this.topicsList)]],
      color: [item ? item.layout_color : '', [Validators.required, this.checkIsLightColor]]
    });
  }

  checkTopicDuplicate(valuesToCheck: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } => {
      const controlValue = control.value;

      const isDup = valuesToCheck.some(el => el.name === controlValue);
      console.log(isDup);
      if (!isDup) {
        return null;
      } else {
        return {duplicate: true};
      }
    };
  }

  checkIsLightColor(control: AbstractControl): any {
    const hex = control.value.replace('#', '');
    const cR = parseInt(hex.substr(0, 2), 16);
    const cG = parseInt(hex.substr(2, 2), 16);
    const cB = parseInt(hex.substr(4, 2), 16);
    const brightness = ((cR * 299) + (cG * 587) + (cB * 114)) / 1000;
    if (brightness > 90) {
      return null;
    } else {
      return {tooDark: true};
    }
  }

  openAddTopicModal(template: TemplateRef<any>, item?): void {
    if (item) {
      this.topicIdToChange = item.id;
      this.isEdit = true;
      this.createTopicFormInit(item);
      setTimeout(() => {
        this.colorsData.current = item.layout_color;
        this.createTopicForm.controls.color.setValue(this.colorsData.current);
      }, 0);
    } else {
      this.createTopicFormInit();
    }
    this.modalTopicRef = this.modalService.show(
      template,
      Object.assign( { class: 'createTopic', backdrop: 'static', keyboard: 'false'})
    );
  }

  setCurrentColor(color): void {
    this.colorsData.current = color;
    this.createTopicForm.controls.color.setValue(this.colorsData.current);
    this.colorsDropdown = false;
  }

  createTopicSubmit(e, f): void {
    this.loadingIndicator = true;
    console.log(f);
    const objToSend = {
      id: this.isEdit ? this.topicIdToChange : null,
      name: f.value.name,
      layout_color: f.value.color
    };
    console.log(objToSend);
    if (this.isEdit) {
      this.questionsService.editTopic(objToSend).subscribe(res => {
        this.AlertService.success('Тема изменена');
        this.topicIdToChange = null;
        this.modalTopicRef.hide();
        this.loadingIndicator = false;
        this.getTopics();
      }, error => {
        this.AlertService.error(error.message);
        this.loadingIndicator = false;
      });
    } else {
      this.questionsService.createNewTopic(objToSend).subscribe(res => {
        this.AlertService.success('Тема добавлена');
        this.topicIdToChange = null;
        this.modalTopicRef.hide();
        this.loadingIndicator = false;
        this.getTopics();
      }, error => {
        this.AlertService.error(error.message);
        this.loadingIndicator = false;
      });
    }
  }

  deleteTopic(): void{
    this.loadingIndicator = true;
    this.questionsService.deleteTopic(this.topicIdToChange).subscribe(res => {
      console.log(res);
      this.topicIdToChange = null;
      this.modalConfirmRef.hide();
      this.loadingIndicator = false;
      this.AlertService.success('Вы успешно удалили тему');
      this.getTopics();
    }, err => {
      console.log('err');
    });
  }

  openDeleteModal(template: TemplateRef<any>, id): void {
    this.topicIdToChange = id;
    this.modalConfirmRef = this.modalService.show(template, Object.assign( { class: 'confirmModal'}));
  }

}
