<div class="container-fluid topics-list-page">
  <div class="row">
    <div class="top-bar col-12">
      <div class="page-title">
        Темы
      </div>
      <div class="controls">
        <button class="main-action-btn contra-colors no-icon-btn" (click)="openAddTopicModal(createTopic)">
          Добавить новую тему
        </button>
      </div>
    </div>
    <div class="main-content col-12">
      <div class="datatable-wrapper">
        <div class="filters">
          <div class="search">
            <!--<label class="search-label" for="pConfig.search">
              <i class="fas fa-search"></i>
            </label>
            <input placeholder="Поиск..." (keyup)="search($event)" [(ngModel)]="pConfig.search" id="pConfig.search" name='search' class="search-input">-->
          </div>
          <div class="items-per-page">
            <label></label>
            <div class="per-page-indicators">
              <button [ngClass]="{'active': pConfig.itemsPerPage === 1}" (click)="perPageChange(1)">1</button>
              <button [ngClass]="{'active': pConfig.itemsPerPage === 10}" (click)="perPageChange(10)">10</button>
              <button [ngClass]="{'active': pConfig.itemsPerPage === 100}" (click)="perPageChange(100)">100</button>
              <button [ngClass]="{'active': pConfig.itemsPerPage === 500}" (click)="perPageChange(500)">500</button>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <table>
            <thead>
            <tr>
              <th class="topic_id">Цвет</th>
              <th class="topic" (click)="sortBy('name')">
                <span>Название</span>
                <span class="sort_icons" [hidden]="pConfig.sortField != 'name'">
                  <i class="fas fa-sort-down" [class.sort_active]="pConfig.sortField == 'name' && pConfig.reverseOrder == true"></i>
                  <i class="fas fa-sort-up" [class.sort_active]="pConfig.sortField == 'name' && pConfig.reverseOrder == false"></i>
                </span>
              </th>
              <th class="actions"></th>
            </tr>
            </thead>
            <tbody *ngIf="topicsList">
            <tr class="hover_rows" *ngFor="let i of topicsList | orderBy:pConfig.sortField:pConfig.reverseOrder | paginate: {
                      id: 'topics_list_pagination',
                      itemsPerPage: pConfig.itemsPerPage,
                      currentPage: pConfig.currentPage,
                      totalItems: pConfig.totalItems
                     }">
              <td>
                <div class="color_wrapper" [style]="{backgroundColor: i.layout_color}"><!--{{i.id}}--></div>
              </td>
              <td>
                {{i.name}}
              </td>
              <td class="actions" (click)="$event.stopPropagation();">
                <div class="actions-wr">
                  <span class="edit" (click)="openAddTopicModal(createTopic, i)"></span>
                  <span class="trash" (click)="openDeleteModal(confirmModal, i.id);"></span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-footer" *ngIf="topicsList">
          <div class="items-total-wrapper">
            <!--<span>
              {{'Всего:' + pConfig.totalItems}}
            </span>-->
          </div>
          <pagination-controls  id="topics_list_pagination"
                                class="table-pagination"
                                (pageChange)="pageChange($event)"
                                maxSize="5"
                                directionLinks="false"
                                autoHide="true"
                                responsive="false">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmModal>
  <div class="modal-body">
    <p>Ви действительно хотите удалить этот вопрос?</p>
  </div>
  <div class="modal-footer">
    <button class="secondary-action-btn" (click)="modalConfirmRef.hide()">
      Не удалять
    </button>
    <button (click)="deleteTopic()" [disabled]="loadingIndicator" class="main-action-btn delete">
      Удалить
    </button>
  </div>
</ng-template>

<ng-template #createTopic>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      <span *ngIf="!isEdit">Добавить новую тему</span>
      <span *ngIf="isEdit">Изменить тему</span>
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalTopicRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="createTopicForm" name="createTopicForm" id="createTopicForm" [formGroup]="createTopicForm"  #f="ngForm" (ngSubmit)="createTopicForm.valid && createTopicSubmit($event, createTopicForm)" [class.ng-submitted-error]="f.submitted && f.form.invalid" novalidate>
      <div class="form-group">
        <div class="input-wrapper">
          <label class="left-col" for="name">Название темы:</label>
          <input class="right-col" type="text" formControlName="name" id="name">
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && createTopicForm.controls.name.errors">
          <p *ngIf="createTopicForm.controls.name.errors.required">Поле, обязательное для заполнения</p>
          <p *ngIf="createTopicForm.controls.name.errors.duplicate">Тема уже существует</p>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper">
          <label class="left-col">Определите цвет темы</label>
          <div class="right-col current-color color-item" (click)="colorsDropdown = !colorsDropdown" [style]="{backgroundColor: colorsData.current}"></div>
          <div class="color-dropdown" [hidden]="!colorsDropdown">
            <button type="button" class="close" (click)="colorsDropdown = false">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="title">Выберите существующий цвет:</div>
            <span class="helper-text" [hidden]="colorsData.list">Задайте тему чтобы отобразились цвета</span>
            <div class="color-item-wr" *ngFor="let item of topicsList" (click)="setCurrentColor(item.layout_color)">
              <div class="color-item-title" [tooltip]="item.name">{{item.name}}</div>
              <div class="color-item" [style]="{backgroundColor: item.layout_color}"></div>
            </div>
            <div class="custom-colors">
              <div class="custom-colors-header">
                <span>Выберите другой цвет</span>
                <input type="color" id="topic_color" (change)="setCurrentColor($event.target.value)">
              </div>
              <!--<div *ngFor="let item of colorsData.custom_list; let i = index" (click)="setActive(item)" [style]="{backgroundColor: item.color}" class="color-item" [class.active]="item.active"></div>-->
            </div>
          </div>
          <!--<input class="right-col" type="color" formControlName="color" id="topic_color">-->
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && createTopicForm.controls.color.errors">
          <p *ngIf="createTopicForm.controls.color.errors.required">Поле, обязательное для заполнения</p>
          <p *ngIf="createTopicForm.controls.color.errors.tooDark">Выберите, пожалуйста, более светлый цвет темы для лучшей читабельности вопросов</p>
        </div>
      </div>
    </form>
    <div class="modal-footer">
      <button [disabled]="loadingIndicator" class="main-action-btn no-icon-btn" form="createTopicForm">
        Подтвердить
      </button>
    </div>
  </div>
</ng-template>

