import {Directive, ElementRef, OnInit, Input, Output, EventEmitter} from '@angular/core';
declare let $: any;

@Directive({
  selector: '[daterangepicker]'
})

export class DateRangePickerDirective implements OnInit {
  @Input() options: Object = {};
  @Output() selected: any = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    $(this.elementRef.nativeElement)
      .daterangepicker(this.options, this.dateCallback.bind(this));
  }

  dateCallback(start, end, label) {
    const obj = {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD')
    };
    this.selected.emit(obj);
  }
}
