import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { EnvService } from './env.service';
import { AlertService } from './alert.service';
import { User } from '../_MODELS';

@Injectable()
export class AuthenticationService {

  constructor(
    private env: EnvService,
    private http: HttpClient,
    private router: Router,
    private _AlertService: AlertService
  ) {}

  login(email: string, password: string) {
    console.log(this.env.apiBase);
    return this.http.post<any>(`${this.env.apiBase}/admin/login`, {email, password}).subscribe(res => {
      const user = new User(res);
      console.log(res);
      if (user && user['api_key']) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        console.log(user);
        this._AlertService.success('Вы успешно вошли в систему');
        this.router.navigate(['/']);
      }
    }, err => {
      console.log(err);
      this._AlertService.error(err.error);
    });
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
