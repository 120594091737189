import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './_SHARED/modules/shared.module';
import { LoginComponent } from './auth/login/login.component';
import { ErrorPageComponent } from './_SHARED/components/error-page/error-page.component';
import { FooterComponent } from './_SHARED/components/footer/footer.component';
import { HeaderComponent } from './_SHARED/components/header/header.component';
import { AlertComponent } from './_SHARED/components/alert/alert.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';




// SERVICES
import {
  EnvServiceProvider,
  SharedService,
  AlertService,
  AuthenticationService, QuestionsService
} from './_SERVICES';

// GUARDS
import { AuthGuard } from './_GUARDS';

// INTERCEPTORS
import { JwtInterceptor, EncodeHttpParamsInterceptor } from './_HELPERS';
import {ChartsModule} from 'ng2-charts';
import { TopicsComponent } from './topics/topics.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorPageComponent,
    FooterComponent,
    HeaderComponent,
    AlertComponent,
    TopicsComponent,
  ],
  imports: [
    SharedModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    ChartsModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    EnvServiceProvider,
    AuthGuard,
    AuthenticationService,
    SharedService,
    AlertService,
    QuestionsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
