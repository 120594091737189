export class User {
  id: number;
  api_key: string;
  name: string;
  role: string;

  constructor(response: User) {
    console.log(response);
    this.id = response.id;
    this.api_key = response.api_key;
    this.name = response.name;
    this.role = response.role;
  }

}
