import { Component, OnInit } from '@angular/core';
import {AlertService, AuthenticationService, EnvService, SharedService} from "../../../_SERVICES";
import {Router, NavigationEnd, ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  USER: any;
  currentRoute;

  constructor(
    private _AuthenticationService: AuthenticationService,
    private _SharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private _Env: EnvService,
    private _AlertService: AlertService
  ) {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.USER = JSON.parse(localStorage.getItem('currentUser'));
        this.currentRoute = val;
      }
    });
  }

  ngOnInit() {}

  public logout(): void {
    this._AuthenticationService.logout();
  }
}
