import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import { EnvService } from './env.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  USER: any;

  constructor(
    private env: EnvService,
    private http: HttpClient,
    private _AlertService: AlertService
  ) {}

  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }

  public getUsersAnalytics(data): Observable<any> {
    const url = `${this.env.apiBase}/admin/users/statistic`;
    this.getUserData();
    console.log(data.itemsPerPage);
    const params = new HttpParams()
      .append('date_from', data.date_from)
      .append('date_to', data.date_to);
    return this.http.get<Object>(url, {params});
  }

}
